import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PayToPrefill } from "../../pages/pay-to/model/prefill-payto.model";

@Injectable({
  "providedIn": "root",
})
export class EmbedService {
  prefillData$ = new BehaviorSubject<PayToPrefill>(null);
}
